// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-18-urodziny-questy-js": () => import("./../../../src/pages/18-urodziny-questy.js" /* webpackChunkName: "component---src-pages-18-urodziny-questy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-7-wyzwan-w-serwisie-js": () => import("./../../../src/pages/7-wyzwan-w-serwisie.js" /* webpackChunkName: "component---src-pages-7-wyzwan-w-serwisie-js" */),
  "component---src-pages-analiza-przedwdrozeniowa-js": () => import("./../../../src/pages/analiza-przedwdrozeniowa.js" /* webpackChunkName: "component---src-pages-analiza-przedwdrozeniowa-js" */),
  "component---src-pages-aplikacja-crm-do-pracy-w-terenie-js": () => import("./../../../src/pages/aplikacja-crm-do-pracy-w-terenie.js" /* webpackChunkName: "component---src-pages-aplikacja-crm-do-pracy-w-terenie-js" */),
  "component---src-pages-aplikacja-do-obslugi-zlecen-js": () => import("./../../../src/pages/aplikacja-do-obslugi-zlecen.js" /* webpackChunkName: "component---src-pages-aplikacja-do-obslugi-zlecen-js" */),
  "component---src-pages-automatyzacja-sprzedazy-js": () => import("./../../../src/pages/automatyzacja-sprzedazy.js" /* webpackChunkName: "component---src-pages-automatyzacja-sprzedazy-js" */),
  "component---src-pages-b-2-b-dla-erp-js": () => import("./../../../src/pages/b2b-dla-erp.js" /* webpackChunkName: "component---src-pages-b-2-b-dla-erp-js" */),
  "component---src-pages-baza-urzadzen-js": () => import("./../../../src/pages/baza-urzadzen.js" /* webpackChunkName: "component---src-pages-baza-urzadzen-js" */),
  "component---src-pages-baza-wiedzy-js": () => import("./../../../src/pages/baza-wiedzy.js" /* webpackChunkName: "component---src-pages-baza-wiedzy-js" */),
  "component---src-pages-bezpieczenstwo-oprogramowania-js": () => import("./../../../src/pages/bezpieczenstwo-oprogramowania.js" /* webpackChunkName: "component---src-pages-bezpieczenstwo-oprogramowania-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-development-manager-js": () => import("./../../../src/pages/business-development-manager.js" /* webpackChunkName: "component---src-pages-business-development-manager-js" */),
  "component---src-pages-cele-sprzedazowe-smart-js": () => import("./../../../src/pages/cele-sprzedazowe-smart.js" /* webpackChunkName: "component---src-pages-cele-sprzedazowe-smart-js" */),
  "component---src-pages-certyfikat-iso-questy-js": () => import("./../../../src/pages/certyfikat-iso-questy.js" /* webpackChunkName: "component---src-pages-certyfikat-iso-questy-js" */),
  "component---src-pages-co-ogranicza-twoja-sprzedaz-js": () => import("./../../../src/pages/co-ogranicza-twoja-sprzedaz.js" /* webpackChunkName: "component---src-pages-co-ogranicza-twoja-sprzedaz-js" */),
  "component---src-pages-co-to-jest-crm-js": () => import("./../../../src/pages/co-to-jest-crm.js" /* webpackChunkName: "component---src-pages-co-to-jest-crm-js" */),
  "component---src-pages-copywriter-js": () => import("./../../../src/pages/copywriter.js" /* webpackChunkName: "component---src-pages-copywriter-js" */),
  "component---src-pages-crm-dla-firmy-it-js": () => import("./../../../src/pages/crm-dla-firmy-it.js" /* webpackChunkName: "component---src-pages-crm-dla-firmy-it-js" */),
  "component---src-pages-czym-jest-platforma-b-2-b-js": () => import("./../../../src/pages/czym-jest-platforma-b2b.js" /* webpackChunkName: "component---src-pages-czym-jest-platforma-b-2-b-js" */),
  "component---src-pages-dashboard-managera-js": () => import("./../../../src/pages/dashboard-managera.js" /* webpackChunkName: "component---src-pages-dashboard-managera-js" */),
  "component---src-pages-dla-partnera-js": () => import("./../../../src/pages/dla-partnera.js" /* webpackChunkName: "component---src-pages-dla-partnera-js" */),
  "component---src-pages-doradca-klienta-js": () => import("./../../../src/pages/doradca-klienta.js" /* webpackChunkName: "component---src-pages-doradca-klienta-js" */),
  "component---src-pages-dziekujemy-kontakt-partner-js": () => import("./../../../src/pages/dziekujemy-kontakt-partner.js" /* webpackChunkName: "component---src-pages-dziekujemy-kontakt-partner-js" */),
  "component---src-pages-frontend-developer-js": () => import("./../../../src/pages/frontend-developer.js" /* webpackChunkName: "component---src-pages-frontend-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integracja-crm-z-systemem-handlowym-js": () => import("./../../../src/pages/integracja-crm-z-systemem-handlowym.js" /* webpackChunkName: "component---src-pages-integracja-crm-z-systemem-handlowym-js" */),
  "component---src-pages-integracja-systemow-erp-js": () => import("./../../../src/pages/integracja-systemow-erp.js" /* webpackChunkName: "component---src-pages-integracja-systemow-erp-js" */),
  "component---src-pages-junior-developer-js": () => import("./../../../src/pages/junior-developer.js" /* webpackChunkName: "component---src-pages-junior-developer-js" */),
  "component---src-pages-junior-project-manager-js": () => import("./../../../src/pages/junior-project-manager.js" /* webpackChunkName: "component---src-pages-junior-project-manager-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kontakt-dziekujemy-js": () => import("./../../../src/pages/kontakt-dziekujemy.js" /* webpackChunkName: "component---src-pages-kontakt-dziekujemy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lejek-sprzedazy-crm-js": () => import("./../../../src/pages/lejek-sprzedazy-crm.js" /* webpackChunkName: "component---src-pages-lejek-sprzedazy-crm-js" */),
  "component---src-pages-marketing-hero-js": () => import("./../../../src/pages/marketing-hero.js" /* webpackChunkName: "component---src-pages-marketing-hero-js" */),
  "component---src-pages-metody-finansowania-js": () => import("./../../../src/pages/metody-finansowania.js" /* webpackChunkName: "component---src-pages-metody-finansowania-js" */),
  "component---src-pages-modul-serwisowy-js": () => import("./../../../src/pages/modul-serwisowy.js" /* webpackChunkName: "component---src-pages-modul-serwisowy-js" */),
  "component---src-pages-narzedzia-crm-online-js": () => import("./../../../src/pages/narzedzia-crm-online.js" /* webpackChunkName: "component---src-pages-narzedzia-crm-online-js" */),
  "component---src-pages-nowe-biuro-questy-js": () => import("./../../../src/pages/nowe-biuro-questy.js" /* webpackChunkName: "component---src-pages-nowe-biuro-questy-js" */),
  "component---src-pages-o-questy-js": () => import("./../../../src/pages/o-questy.js" /* webpackChunkName: "component---src-pages-o-questy-js" */),
  "component---src-pages-obsluga-reklamacji-online-js": () => import("./../../../src/pages/obsluga-reklamacji-online.js" /* webpackChunkName: "component---src-pages-obsluga-reklamacji-online-js" */),
  "component---src-pages-ofertowanie-crm-jak-zarzadzac-js": () => import("./../../../src/pages/ofertowanie-crm-jak-zarzadzac.js" /* webpackChunkName: "component---src-pages-ofertowanie-crm-jak-zarzadzac-js" */),
  "component---src-pages-ogloszenie-js": () => import("./../../../src/pages/ogloszenie.js" /* webpackChunkName: "component---src-pages-ogloszenie-js" */),
  "component---src-pages-piec-dobrych-praktyk-budowania-relacji-z-klientami-js": () => import("./../../../src/pages/piec-dobrych-praktyk-budowania-relacji-z-klientami.js" /* webpackChunkName: "component---src-pages-piec-dobrych-praktyk-budowania-relacji-z-klientami-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-proces-sprzedazy-js": () => import("./../../../src/pages/proces-sprzedazy.js" /* webpackChunkName: "component---src-pages-proces-sprzedazy-js" */),
  "component---src-pages-program-do-tworzenia-ofert-handlowych-js": () => import("./../../../src/pages/program-do-tworzenia-ofert-handlowych.js" /* webpackChunkName: "component---src-pages-program-do-tworzenia-ofert-handlowych-js" */),
  "component---src-pages-project-manager-js": () => import("./../../../src/pages/project-manager.js" /* webpackChunkName: "component---src-pages-project-manager-js" */),
  "component---src-pages-promowanie-b-2-b-js": () => import("./../../../src/pages/promowanie-b2b.js" /* webpackChunkName: "component---src-pages-promowanie-b-2-b-js" */),
  "component---src-pages-przeglady-cykliczne-js": () => import("./../../../src/pages/przeglady-cykliczne.js" /* webpackChunkName: "component---src-pages-przeglady-cykliczne-js" */),
  "component---src-pages-questy-na-cebit-js": () => import("./../../../src/pages/questy-na-cebit.js" /* webpackChunkName: "component---src-pages-questy-na-cebit-js" */),
  "component---src-pages-questy-na-targach-e-handlu-2018-w-krakowie-js": () => import("./../../../src/pages/questy-na-targach-e-handlu-2018-w-krakowie.js" /* webpackChunkName: "component---src-pages-questy-na-targach-e-handlu-2018-w-krakowie-js" */),
  "component---src-pages-questy-w-law-business-quality-js": () => import("./../../../src/pages/questy-w-law-business-quality.js" /* webpackChunkName: "component---src-pages-questy-w-law-business-quality-js" */),
  "component---src-pages-ranking-crm-dla-firm-funkcje-js": () => import("./../../../src/pages/ranking-crm-dla-firm-funkcje.js" /* webpackChunkName: "component---src-pages-ranking-crm-dla-firm-funkcje-js" */),
  "component---src-pages-raport-pracy-js": () => import("./../../../src/pages/raport-pracy.js" /* webpackChunkName: "component---src-pages-raport-pracy-js" */),
  "component---src-pages-raportowanie-sprzedazy-js": () => import("./../../../src/pages/raportowanie-sprzedazy.js" /* webpackChunkName: "component---src-pages-raportowanie-sprzedazy-js" */),
  "component---src-pages-rozbudowa-kanalu-sprzedazy-posredniej-js": () => import("./../../../src/pages/rozbudowa-kanalu-sprzedazy-posredniej.js" /* webpackChunkName: "component---src-pages-rozbudowa-kanalu-sprzedazy-posredniej-js" */),
  "component---src-pages-rozwiazania-dedykowane-js": () => import("./../../../src/pages/rozwiazania-dedykowane.js" /* webpackChunkName: "component---src-pages-rozwiazania-dedykowane-js" */),
  "component---src-pages-samoobsluga-b-2-b-js": () => import("./../../../src/pages/samoobsluga-b2b.js" /* webpackChunkName: "component---src-pages-samoobsluga-b-2-b-js" */),
  "component---src-pages-sklep-b-2-b-jak-zaczac-js": () => import("./../../../src/pages/sklep-b2b-jak-zaczac.js" /* webpackChunkName: "component---src-pages-sklep-b-2-b-jak-zaczac-js" */),
  "component---src-pages-skuteczne-wdrozenie-crm-czyli-jak-zarzadzic-zmiana-w-organizacji-w-nowej-sprzedazy-js": () => import("./../../../src/pages/skuteczne-wdrozenie-crm-czyli-jak-zarzadzic-zmiana-w-organizacji-w-nowej-sprzedazy.js" /* webpackChunkName: "component---src-pages-skuteczne-wdrozenie-crm-czyli-jak-zarzadzic-zmiana-w-organizacji-w-nowej-sprzedazy-js" */),
  "component---src-pages-software-developer-js": () => import("./../../../src/pages/software-developer.js" /* webpackChunkName: "component---src-pages-software-developer-js" */),
  "component---src-pages-software-support-engineer-js": () => import("./../../../src/pages/software-support-engineer.js" /* webpackChunkName: "component---src-pages-software-support-engineer-js" */),
  "component---src-pages-specjalista-ds-administracji-js": () => import("./../../../src/pages/specjalista-ds-administracji.js" /* webpackChunkName: "component---src-pages-specjalista-ds-administracji-js" */),
  "component---src-pages-specjalista-ds-marketingu-js": () => import("./../../../src/pages/specjalista-ds-marketingu.js" /* webpackChunkName: "component---src-pages-specjalista-ds-marketingu-js" */),
  "component---src-pages-sprzedaz-i-marketing-js": () => import("./../../../src/pages/sprzedaz-i-marketing.js" /* webpackChunkName: "component---src-pages-sprzedaz-i-marketing-js" */),
  "component---src-pages-system-b-2-b-funkcje-js": () => import("./../../../src/pages/system-b2b-funkcje.js" /* webpackChunkName: "component---src-pages-system-b-2-b-funkcje-js" */),
  "component---src-pages-system-crm-a-miekka-windykacja-js": () => import("./../../../src/pages/system-crm-a-miekka-windykacja.js" /* webpackChunkName: "component---src-pages-system-crm-a-miekka-windykacja-js" */),
  "component---src-pages-system-do-obslugi-zgloszen-serwisowych-js": () => import("./../../../src/pages/system-do-obslugi-zgłoszen-serwisowych.js" /* webpackChunkName: "component---src-pages-system-do-obslugi-zgloszen-serwisowych-js" */),
  "component---src-pages-ui-designer-js": () => import("./../../../src/pages/ui-designer.js" /* webpackChunkName: "component---src-pages-ui-designer-js" */),
  "component---src-pages-umowa-sla-js": () => import("./../../../src/pages/umowa-sla.js" /* webpackChunkName: "component---src-pages-umowa-sla-js" */),
  "component---src-pages-ups-js": () => import("./../../../src/pages/ups.js" /* webpackChunkName: "component---src-pages-ups-js" */),
  "component---src-pages-vlog-js": () => import("./../../../src/pages/vlog.js" /* webpackChunkName: "component---src-pages-vlog-js" */),
  "component---src-pages-warsztaty-sales-process-engineering-js": () => import("./../../../src/pages/warsztaty-sales-process-engineering.js" /* webpackChunkName: "component---src-pages-warsztaty-sales-process-engineering-js" */),
  "component---src-pages-wdrazanie-kultury-pracy-crm-js": () => import("./../../../src/pages/wdrazanie-kultury-pracy-CRM.js" /* webpackChunkName: "component---src-pages-wdrazanie-kultury-pracy-crm-js" */),
  "component---src-pages-wdrozenie-crm-js": () => import("./../../../src/pages/wdrozenie-crm.js" /* webpackChunkName: "component---src-pages-wdrozenie-crm-js" */),
  "component---src-pages-wdrozenie-normy-iso-js": () => import("./../../../src/pages/wdrozenie-normy-iso.js" /* webpackChunkName: "component---src-pages-wdrozenie-normy-iso-js" */),
  "component---src-pages-wdrozenie-panelu-b-2-b-js": () => import("./../../../src/pages/wdrozenie-panelu-b2b.js" /* webpackChunkName: "component---src-pages-wdrozenie-panelu-b-2-b-js" */),
  "component---src-pages-wdrozenie-platformy-b-2-b-js": () => import("./../../../src/pages/wdrozenie-platformy-b2b.js" /* webpackChunkName: "component---src-pages-wdrozenie-platformy-b-2-b-js" */),
  "component---src-pages-wigilia-w-questy-js": () => import("./../../../src/pages/wigilia-w-questy.js" /* webpackChunkName: "component---src-pages-wigilia-w-questy-js" */),
  "component---src-pages-wsparcie-sprzedazy-js": () => import("./../../../src/pages/wsparcie-sprzedazy.js" /* webpackChunkName: "component---src-pages-wsparcie-sprzedazy-js" */),
  "component---src-pages-wspierane-obszary-js": () => import("./../../../src/pages/wspierane-obszary.js" /* webpackChunkName: "component---src-pages-wspierane-obszary-js" */),
  "component---src-pages-wywiady-js": () => import("./../../../src/pages/wywiady.js" /* webpackChunkName: "component---src-pages-wywiady-js" */),
  "component---src-pages-zalety-elastycznego-crm-js": () => import("./../../../src/pages/zalety-elastycznego-crm.js" /* webpackChunkName: "component---src-pages-zalety-elastycznego-crm-js" */),
  "component---src-pages-zarzadzanie-kampaniami-crm-szanse-sprzedazy-js": () => import("./../../../src/pages/zarzadzanie-kampaniami-crm-szanse-sprzedazy.js" /* webpackChunkName: "component---src-pages-zarzadzanie-kampaniami-crm-szanse-sprzedazy-js" */),
  "component---src-pages-zarzadzanie-uslugami-js": () => import("./../../../src/pages/zarzadzanie-uslugami.js" /* webpackChunkName: "component---src-pages-zarzadzanie-uslugami-js" */),
  "component---src-pages-zarzadzanie-zespolem-marketingu-crm-js": () => import("./../../../src/pages/zarzadzanie-zespolem-marketingu-crm.js" /* webpackChunkName: "component---src-pages-zarzadzanie-zespolem-marketingu-crm-js" */),
  "component---src-pages-zarzadzanie-zespolem-rozproszonym-js": () => import("./../../../src/pages/zarzadzanie-zespolem-rozproszonym.js" /* webpackChunkName: "component---src-pages-zarzadzanie-zespolem-rozproszonym-js" */),
  "component---src-pages-zastepowalnosc-pracownikow-js": () => import("./../../../src/pages/zastepowalnosc-pracownikow.js" /* webpackChunkName: "component---src-pages-zastepowalnosc-pracownikow-js" */)
}

